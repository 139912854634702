import React, { useEffect } from 'react';
import { useRouter } from 'next/router';

const IndexRedirect: React.FC = () => {
    const router = useRouter();

    useEffect(() => {
        router.push('/cuentas');
    }, [ ]);

    return (
        <></>
    );
};

export default IndexRedirect;